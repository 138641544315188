import UFormItem from '@/components/UFormItem'
import Routerx from '@/routerx'
import { http } from '@/utils'
import { Breadcrumb, Button, Card, Col, DatePicker, Flex, Form, Input, Popconfirm, Row, Space, Table, Typography } from 'antd'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import './index.css'

const { Title, Text, Link } = Typography

const { RangePicker } = DatePicker

const Batch = () => {
  const [batch, setBatchList] = useState<{ list: []; count: number }>({
    list: [],
    count: 0,
  })

  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  })

  const [loading, setLoading] = useState(false)

  // 发送接口请求
  useEffect(() => {
    const loadList = async () => {
      setLoading(true)
      const res = await http.post('/v1/batch/list', params)
      const { results, total_count } = res.data
      setBatchList({
        list: results,
        count: total_count,
      })
      setLoading(false)
    }
    loadList()
  }, [params])

  interface BatchTableParams {
    name: string
    created_at?: [Dayjs, Dayjs]
    created_at_begin: string
    created_at_end: string
  }
  const onFinish = (values: BatchTableParams) => {
    const { name, created_at } = values
    // 格式化表单数据
    const _params: BatchTableParams = {
      name: name,
      created_at_begin: '',
      created_at_end: '',
    }
    if (created_at) {
      _params.created_at_begin = created_at[0].format('YYYY-MM-DD')
      _params.created_at_end = created_at[1].format('YYYY-MM-DD')
    }
    // 修改params参数 触发接口再次发起
    setParams({
      ...params,
      ..._params,
    })
  }

  // 删除回调
  const delBatch = async (data: { id: number }) => {
    await http.post(`/v1/batch/delete`, { id: data.id })
    // 更新列表
    setParams({
      page: 1,
      per_page: 10,
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 110,
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '类型',
      dataIndex: 'type_cn',
    },
    {
      title: '状态',
      dataIndex: 'status_cn',
    },
    {
      title: '开始日期',
      dataIndex: 'created_at',
    },
    {
      title: '截止日期',
      dataIndex: 'deadline_str',
    },
    {
      title: '结束日期',
      dataIndex: 'end_at',
    },
    {
      title: '收益率',
      dataIndex: 'roi',
      render: (value: number) => {
        const formattedValue = value.toFixed(2)
        const className = value > 0 ? 'profit-up' : 'profit-down'
        return <span className={className}>{formattedValue}%</span>
      },
    },

    {
      title: '操作',
      render: (data: { id: number }) => {
        return (
          <Space size="middle">
            <Link onClick={() => Routerx.navigate(`/batch/detail/${data.id}`)}>详情</Link>
            <Link onClick={() => Routerx.navigate(`/batchstock/import/${data.id}`)}>导入</Link>
            <Link onClick={() => Routerx.navigate(`/batch/publish/${data.id}`)}>编辑</Link>
            <Popconfirm
              title="确认删除吗?"
              onConfirm={() => delBatch(data)}
              okText="确认"
              cancelText="取消"
            >
              <Link type="danger">删除</Link>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const pageChange = (page: number) => {
    // 拿到当前页参数 修改params 引起接口更新
    setParams({
      ...params,
      page,
    })
  }

  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    setParams({
      page: 1,
      per_page: 10,
    })
  }

  return (
    <Flex
      justify="space-between"
      gap="small"
      vertical
    >
      <Breadcrumb
        style={{ margin: '16px 0 5px 0' }}
        separator=">"
        items={[
          {
            title: '股池',
          },
          {
            title: '批次',
          },
        ]}
      />
      <Title
        level={4}
        style={{ margin: '5px 0 10px 0' }}
      >
        批次
      </Title>
      <Card>
        <Form
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={24}>
            <Col span={6}>
              <UFormItem
                label="名称"
                name="name"
              >
                <Input placeholder="请输入名称" />
              </UFormItem>
            </Col>
            <Col span={6}>
              <UFormItem
                label="创建时间"
                name="created_at"
              >
                {/* 传入locale属性 控制中文显示*/}
                <RangePicker></RangePicker>
              </UFormItem>
            </Col>
            <Col span={6}>
              <UFormItem>
                <Button
                  htmlType="button"
                  onClick={onReset}
                >
                  重置
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 20 }}
                >
                  查询
                </Button>
              </UFormItem>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card>
        <Flex
          justify="space-between"
          style={{ padding: '0 0 16px 0' }}
        >
          <Text>批次列表</Text>
          <Flex
            justify="space-between"
            gap="middle"
          >
            <Button
              type="primary"
              onClick={() => Routerx.navigate(`/batch/publish`)}
            >
              新建
            </Button>
          </Flex>
        </Flex>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={batch.list}
          size="small"
          pagination={{
            position: ['bottomCenter'],
            current: params.page,
            pageSize: params.per_page,
            total: batch.count,
            onChange: pageChange,
          }}
          loading={loading}
        />
      </Card>
    </Flex>
  )
}

export default Batch
